<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">

	  			<!-- Titulo -->
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Descuentos especiales</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterAlumnos  , 'DESCUENTOS_ESPECIALES')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>

	  		  <v-card-text>

	  		  	<!-- Filtros -->
	  		  	<v-row justify="end">
	  		  		<v-spacer></v-spacer>
	  		  		<v-col cols="12" md="7" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<!-- Tabla -->
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="descuentos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
			            id="grupos"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.aplicado="{ item }">
							      <v-chip 
							      	v-if="item.aplicado == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

							    <template v-slot:item.actions="{ item }">
							    	
							    	<v-btn 
							    		color="red"
							    		dark 
							    		x-small
							    		@click="deleteItem(item)"
							    	>
								      <v-icon small>mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"> {{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_alumno }}</strong>
          </span>
        </v-card-title>
        <v-card-text class="pt-0">

        	<!-- SELECCIONAR EL DESCUENTO POR EL QUE SE VA A AGRGAR -->
        	<span>Descuento por:</span>
        	
        	<!-- SABER SI EL DESCUENTO SERÁ POR GRUPO O POR ALUMNO -->
        	<v-radio-group
			      v-model="menuDescuentos"
			      row
			      dense
			      class="mt-0"
			    >
			      <v-radio
			        label="Alumno"
			        :value="1"
			      ></v-radio>
			      <v-radio
			        label="Grupo"
			        :value="2"
			      ></v-radio>
			    </v-radio-group>

			    <!-- DESCUENTO POR ALUMNO -->
			    <v-row>
			    	<v-col cols="12" md="8">
					    <v-autocomplete
					    	v-if="menuDescuentos == 1"
			          :items="alumnos"
			          v-model="editedItem.id_alumno"
			          label="Buscar alumno"
			          item-text="datos_completos"
			          item-value="id_alumno"
			          filled
			          hide-details
			          dense
			          single-line
			        ></v-autocomplete>
			    	</v-col>
			    </v-row>

			    <!-- TIPO DE DESCUENTO POR FECHA O POR SIGUIENTE GRUPO -->
			    <v-row v-if="menuDescuentos">
			    	<v-col cols="12" md="8" lg="6">
			    		<span>Seleccionar el tipo de descuento</span>
					    <v-radio-group
					      v-model="editedItem.tipo_descuento"
					      row
					      dense
					      class="mt-0"
					    >
					      <v-radio
					        label="Siguiente grupo"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Fecha limite"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
					    
					    <!-- POR SI ES FECHA LIMITE -->
					    <v-text-field
					      v-model="editedItem.fecha_limite"
					      filled
					      type="date"
					      label="Fecha limite"
					      dense
					      v-if="editedItem.tipo_descuento == 2"
					    ></v-text-field>

					    <!-- INGRESAR EL MONTO DE DESCUENTO  -->
					    <v-text-field
					      v-model="editedItem.monto"
					      filled
					      type="number"
					      label="Monto de descuento"
					      dense
					      v-if="editedItem.tipo_descuento"
					    ></v-text-field>
			    	</v-col>
			    </v-row>

			    <!-- SERÁ POR GRUPO -->
			    <v-row v-if="menuDescuentos == 2 && editedItem.tipo_descuento">
		    		<v-col cols="12" md="10">
		    			<v-autocomplete
			          :items="grupos"
			          v-model="editedItem.id_grupo"
			          label="Buscar grupo"
			          item-text="grupo"
			          item-value="id_grupo"
			          filled
			          dense
			          single-line
			        ></v-autocomplete>

			        <!-- DESCRIPCIÓN GENERAL DEL GRUPO -->
			        <div v-if="alumnosGrupo" class="text-subtitle-1 black--text">
			        	<b>TOTAL:</b> ${{ alumnosGrupo.costo_grupo }}
			        	<br/>
			        	<b>Nuevo costo:</b> ${{ parseFloat( alumnosGrupo.costo_grupo) - ( parseInt(alumnosSeleccionados.length ) * parseFloat(editedItem.monto))  }}
			        	<br/>
			        </div>

			        <!-- SELECCIONAR A LOS ALUMNOS QUE TENDRÁN ESE DESCUENTO -->
		    			<v-data-table
		    				v-if="editedItem.id_grupo && alumnosGrupo"
						    :headers="headersAlumnos"
						    :items="alumnosGrupo.alumnos"
						    class="elevation-1"
						    :mobile-breakpoint="100"
		            dense
		            show-select
		            v-model="alumnosSeleccionados"
						  >
						    <template v-slot:no-data>
						      <v-btn
						        color="primary"
						        @click="initialize"
						        small
						      >
						        Actualizar
						      </v-btn>
						    </template>

						  </v-data-table>
		    		</v-col>
		    	</v-row>

        </v-card-text>
        <v-card-actions>
        	<v-btn
            color="red"
            dark
            small
            rounded
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="save"
          >
            Gurdar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">¿Seguro que deseas eliminar el descuento?</v-card-title>
        <v-card-actions>
          <v-btn color="red" rounded small dark @click="closeDelete">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded small dark @click="deleteItemConfirm">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';
  import InfoAlumno         from '@/components/inscripciones/InfoAlumno.vue';
  import GruposAlumno       from '@/components/inscripciones/GruposAlumno.vue';
  import Tutor              from '@/components/inscripciones/Tutor.vue';
  import BecaAlumno         from '@/components/inscripciones/BecaAlumno.vue';
  import DialogInscripcion  from '@/components/inscripciones/DialogInscripcion.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
      InfoAlumno,
      GruposAlumno,
      Tutor,
      BecaAlumno,
      DialogInscripcion
    },

    data: () => ({
    	tabs: 0, 
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      editedIndex: -1,

      editedItem: {
        iddescuentos_especiales:0,
				id_alumno:0,
				id_grupo_actual:0,
				id_grupo_aplicado:0,
				monto:0,
				fecha_limite:null,
				tipo_descuento:0,
				aplicado:0,
				id_curso: 0,
      },

      defaultItem: {
        iddescuentos_especiales:0,
				id_alumno:0,
				id_grupo_actual:0,
				id_grupo_aplicado:0,
				monto:0,
				fecha_limite:null,
				tipo_descuento:0,
				aplicado:0,
				id_curso: 0,
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      descuentos:[],
      headers: [
        { text: 'ID'           , value: 'id_alumno'    },
        { text: 'Matricula'    , value: 'matricula'    },
        { text: 'Alumno'       , value: 'alumno'       },
        { text: 'Grupo'        , value: 'grupo'        },
        { text: 'Monto'        , value: 'monto'        },
        { text: 'Aplicado'     , value: 'aplicado'     },
        { text: 'fecha limite' , value: 'fecha_limite' },
        { text: 'Actions'      , value: 'actions'      },
      ],

      headersAlumnos: [
        { text: 'ID'         , value: 'id_alumno'  },
        { text: 'Alumno'     , value: 'alumno'     },
        { text: 'Pagado'     , value: 'pagado'     },
      ],

      alumnos:[],
      alumno: null, 
      tipoDescuento:null,
      menuDescuentos:null,
      fecha_limite: null,
      monto_descuento:0,
      grupo: null,
      curso: null,
      grupos:[],
      alumnosGrupo:null,
      alumnosSeleccionados:[],
      dialogDelete: false,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Descuento' : 'Editar Descuento'
      },

    },

    watch: {
      dialog (val) { val || this.close() },

      tabs( val ) { console.log( val ) },

      ciclo( val ) { if( val ){ this.initialize() } },

      'editedItem.id_grupo'(val){ this.alumnosGrupo = this.grupos.find( el => el.id_grupo == val ) },

      dialogDelete (val) { val || this.closeDelete()},
    },

    async created () {
      await this.initialize()
      await this.getAlumnos()
      await this.getGrupos()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.descuentos = []
        return this.$http.get('descuentos.all').then(response=>{
        	this.descuentos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnos () {
      	this.cargar = true
        this.alumnos = []
        return this.$http.get('descuentos.alumnos').then(response=>{
        	this.alumnos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGrupos() {
      	this.cargar = true
        this.grupos = []
        return this.$http.get('descuentos.grupos').then(response=>{
        	this.grupos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      editItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.descuentos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('descuentos.eliminar/' + this.editedItem.iddescuentos_especiales ).then(response=>{
        	this.validarSuccess( response.data.message )
	        this.closeDelete()
        	this.initialize()
        }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('grupos.update/' + this.editedItem.id_grupo, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
		        this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {

        	if( this.menuDescuentos == 1 ){
		        // Lo mandapos por el EP
	          this.$http.post('descuentos.alumnos.add', this.editedItem).then(response=>{
	        		this.close()
	          	this.initialize()
		        	this.cargar = false
		        }).catch( error =>{
	          	this.validarError( error.response.data.message )
	        	}).finally( () => { this.cargar = false })
        	}else{
        		const payload = {
        			opciones: this.editedItem,
        			alumnos:  this.alumnosSeleccionados
        		}
        		this.$http.post('descuentos.grupos.add', payload).then(response=>{
	        		this.close()
	          	this.initialize()
		        	this.cargar = false
		        }).catch( error =>{
	          	this.validarError( error.response.data.message )
	        	}).finally( () => { this.cargar = false })
        	}
        }
      },

    },
  }
</script>


<style>
  #grupos td:nth-child(1) {
    background-color: #E8E8E8 !important;
  }

  #grupos td{
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

</style>